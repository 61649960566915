var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"common-modal-headline"},[_c('h4',{staticClass:"common-modal-title"},[_vm._v(" "+_vm._s(_vm.isNew ? "Add new driver" : "Edit driver")+" ")]),_c('span',{staticClass:"material-symbols-rounded common-modal-close",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" close ")])]),_c('hr',{staticClass:"my-3"}),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":_vm.msg.type,"dismissible":"","show":_vm.msg.text},model:{value:(_vm.msg.has),callback:function ($$v) {_vm.$set(_vm.msg, "has", $$v)},expression:"msg.has"}},[_vm._v(_vm._s(_vm.msg.text))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required",attrs:{"for":"driver-login"}},[_vm._v("Phone Number")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.login),expression:"form.login"},{name:"mask",rawName:"v-mask",value:('+1 ##########'),expression:"'+1 ##########'"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.login.$error,
            },attrs:{"placeholder":"+1 ##########","type":"text","id":"driver-login","readonly":!_vm.isNew},domProps:{"value":(_vm.form.login)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "login", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.login.$error)?_c('div',{staticClass:"invalid-feedback"},[(
                !_vm.$v.form.login.required ||
                !_vm.$v.form.login.minLength ||
                !_vm.$v.form.login.maxLength
              )?_c('div',[_vm._v(" Phone Number is required ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{class:{ required: _vm.isNew },attrs:{"for":"driver-passw"}},[_vm._v("Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showPassw ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.passw),expression:"form.passw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.passw.$error,
            },attrs:{"id":"driver-passw","placeholder":"Enter your password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.passw)?_vm._i(_vm.form.passw,null)>-1:(_vm.form.passw)},on:{"change":function($event){var $$a=_vm.form.passw,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "passw", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "passw", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "passw", $$c)}}}}):((_vm.showPassw ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.passw),expression:"form.passw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.passw.$error,
            },attrs:{"id":"driver-passw","placeholder":"Enter your password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.passw,null)},on:{"change":function($event){return _vm.$set(_vm.form, "passw", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.passw),expression:"form.passw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.passw.$error,
            },attrs:{"id":"driver-passw","placeholder":"Enter your password","type":_vm.showPassw ? 'text' : 'password'},domProps:{"value":(_vm.form.passw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "passw", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showPassw = !_vm.showPassw}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showPassw)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && _vm.$v.form.passw.$error)?_c('div',{staticClass:"invalid-feedback"},[(_vm.isNew && !_vm.$v.form.passw.required)?_c('div',[_vm._v(" Password is required ")]):_vm._e(),(!_vm.$v.form.passw.minLength)?_c('div',[_vm._v("Passwords min 8")]):_vm._e(),(!_vm.$v.form.passw.maxLength)?_c('div',[_vm._v("Passwords max 16")]):_vm._e(),(!_vm.$v.form.passw.valid)?_c('div',[_vm._v(" Passwords is not valid, must include: A-Z, a-z, 0-9, #?!@$%^&*- ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{class:{ required: _vm.isNew },attrs:{"for":"driver-repassw"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"input-group input-group-merge"},[((_vm.showRepassw ? 'text' : 'password')==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repassw),expression:"form.repassw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.repassw.$error,
            },attrs:{"id":"driver-repassw","placeholder":"Confirm your password","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.form.repassw)?_vm._i(_vm.form.repassw,null)>-1:(_vm.form.repassw)},on:{"change":function($event){var $$a=_vm.form.repassw,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "repassw", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "repassw", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "repassw", $$c)}}}}):((_vm.showRepassw ? 'text' : 'password')==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repassw),expression:"form.repassw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.repassw.$error,
            },attrs:{"id":"driver-repassw","placeholder":"Confirm your password","type":"radio"},domProps:{"checked":_vm._q(_vm.form.repassw,null)},on:{"change":function($event){return _vm.$set(_vm.form, "repassw", null)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.repassw),expression:"form.repassw"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.repassw.$error,
            },attrs:{"id":"driver-repassw","placeholder":"Confirm your password","type":_vm.showRepassw ? 'text' : 'password'},domProps:{"value":(_vm.form.repassw)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "repassw", $event.target.value)}}}),_c('div',{staticClass:"input-group-append",attrs:{"role":"button"},on:{"click":function($event){_vm.showRepassw = !_vm.showRepassw}}},[_c('div',{staticClass:"input-group-text"},[(_vm.showRepassw)?_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility")]):_c('span',{staticClass:"material-symbols-rounded fz-18"},[_vm._v("visibility_off")])])]),(_vm.submitted && _vm.$v.form.repassw.$error)?_c('div',{staticClass:"invalid-feedback"},[(_vm.isNew && !_vm.$v.form.repassw.required)?_c('div',[_vm._v(" Confirm Password is required ")]):_vm._e(),(!_vm.$v.form.repassw.sameAsPassword)?_c('div',[_vm._v(" Passwords are not matched ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-0"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v("Driver License "),_c('span',{staticClass:"text-muted"},[_vm._v("(jpeg, png, pdf; size limit: "+_vm._s(_vm.fileSizeMaxMb)+"MB)")])]),_c('div',{staticClass:"input-group input-group-merge custom-input-file",class:{
            'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.file.$error,
          }},[_c('input',{ref:"file",staticClass:"form-control custom-input-file-input",attrs:{"id":"driver-file","type":"file","accept":"image/jpeg, image/png, application/pdf"},on:{"change":_vm.fileChanged}}),(_vm.form.file && _vm.form.file.length)?_c('div',{staticClass:"custom-input-file-text"},[(!_vm.form.file[0].path)?[_c('span',{staticClass:"material-symbols-rounded custom-input-file-text-ico mr-1"},[_vm._v(" picture_as_pdf ")]),_vm._v(_vm._s(_vm.form.file[0].name)+" ")]:_c('a',{attrs:{"href":_vm.form.file[0].name},on:{"click":function($event){$event.preventDefault();return _vm.getFile(_vm.form.file[0])}}},[_c('span',{staticClass:"material-symbols-rounded custom-input-file-text-ico mr-1"},[_vm._v(" picture_as_pdf ")]),_vm._v(_vm._s(_vm.form.file[0].name)+" ")]),_c('span',{staticClass:"material-symbols-rounded custom-input-file-reset",attrs:{"title":"Reset file"},on:{"click":_vm.resetFile}},[_vm._v(" cancel ")])],2):_c('label',{staticClass:"custom-input-file-label",attrs:{"for":"driver-file"}},[_vm._v("Upload "),_c('span',{staticClass:"material-symbols-rounded custom-input-file-label-ico"},[_vm._v(" upload ")])])]),(_vm.isNew && _vm.submitted && _vm.$v.form.file.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.file.required)?_c('div',[_vm._v("Driver License is required")]):_vm._e()]):_vm._e(),_c('b-alert',{staticClass:"mt-1 mb-0",attrs:{"variant":"danger"},model:{value:(_vm.fileAlert),callback:function ($$v) {_vm.fileAlert=$$v},expression:"fileAlert"}},[_vm._v(" File size too big, limit: "+_vm._s(_vm.fileSizeMaxMb)+"MB ")])],1)]),_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('label',{staticClass:"required",attrs:{"for":"driver-first_name"}},[_vm._v("First Name")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.first_name),expression:"form.first_name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.first_name.$error,
            },attrs:{"id":"driver-first_name","placeholder":"Enter First Name"},domProps:{"value":(_vm.form.first_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "first_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.first_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.first_name.required)?_c('div',[_vm._v(" First Name is required ")]):_vm._e(),(!_vm.$v.form.first_name.minLength)?_c('div',[_vm._v("First name min 1")]):_vm._e(),(!_vm.$v.form.first_name.maxLength)?_c('div',[_vm._v("First name max 30")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"driver-second_name"}},[_vm._v("Middle Name")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.second_name),expression:"form.second_name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.second_name.$error,
            },attrs:{"id":"driver-second_name","placeholder":"Enter Middle Name"},domProps:{"value":(_vm.form.second_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "second_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.second_name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Middle Name max 30 ")]):_vm._e()])]),_c('div',{staticClass:"form-group mb-3"},[_c('label',{attrs:{"for":"driver-last_name"}},[_vm._v("Last Name")]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.last_name),expression:"form.last_name"}],staticClass:"form-control",class:{
              'is-invalid': _vm.submitted && _vm.$v.form.last_name.$error,
            },attrs:{"id":"driver-last_name","placeholder":"Enter Last Name"},domProps:{"value":(_vm.form.last_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "last_name", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.last_name.$error)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Last Name max 30 ")]):_vm._e()])])])]),_c('hr',{staticClass:"mt-2 mb-1"}),_c('div',{staticClass:"mt-3 text-center text-md-left"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" "+_vm._s(_vm.isNew ? "Add driver" : "Save changes")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }